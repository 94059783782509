import './App.css';

function App() {
  return (
    <div className="App">
      <header>
        <div class="logo"><span>SCADA | EMC</span></div>
        <div class="details">
          <span>REG: EMC0124578956</span>
          <span>EMAIL: contact@emc.com</span>
        </div>
      </header>
      <div class="update"><marquee direction="left"><span style={{color:"red", fontWeight: 600}}>Lorem ipsum dolor sit</span> amet consectetur <span style={{color:"yellow", fontWeight: 600}}>adipisicing</span> elit. It`s made by EMC &copy; 2023</marquee></div>
      <div class="main-container">
        <div class="view">
          <div class="canvas">
            <div class="tank-container">
              <div class="tank-holder">
                <div class="indicator upper-indicator">
                  <div class="lamp-name"><span>s</span></div>
                  <div class="lamp">
                    <div class="lamp-first"></div>
                    <div class="lamp-second"></div>
                  </div>
                  <div class="variable-name"><span>V111</span></div>
                </div>
                <div class="tank">
                  <span class="tank-upper"></span>
                  <span class="tank-name">Rinse tank<br/>T91</span>
                  <span class="tank-lower first-tank-lower"></span>
                </div>
                <div class="indicator lower-indicator">
                  <div class="lamp-name"><span>s</span></div>
                  <div class="lamp">
                    <div class="lamp-first"></div>
                    <div class="lamp-second"></div>
                  </div>
                  <div class="variable-name"><span>V101</span></div>
                </div>
              </div>
              <div class="tank-holder">
                <div class="indicator upper-indicator">
                  <div class="lamp-name"><span>s</span></div>
                  <div class="lamp">
                    <div class="lamp-first"></div>
                    <div class="lamp-second"></div>
                  </div>
                  <div class="variable-name"><span>V113</span></div>
                </div>
                <div class="tank">
                  <span class="tank-upper"></span>
                  <span class="tank-name">LYF tank<br/>T93</span>
                  <span class="tank-lower middle-tank-lower"></span>
                </div>
                <div class="indicator lower-indicator">
                  <div class="lamp-name"><span>s</span></div>
                  <div class="lamp">
                    <div class="lamp-first"></div>
                    <div class="lamp-second"></div>
                  </div>
                  <div class="variable-name"><span>V103</span></div>
                </div>
                <div class="tank-circle-indic-holder">
                  <div class="circle-indic tr1">
                    <span class="tr-val">54.7</span>
                    <hr/>
                      <span class="tr-val">68</span>
                      <span class="tr-name">TR1</span>
                  </div>
                </div>
              </div>
              <div class="tank-holder">
                <div class="indicator upper-indicator">
                  <div class="lamp-name"><span>s</span></div>
                  <div class="lamp">
                    <div class="lamp-first"></div>
                    <div class="lamp-second"></div>
                  </div>
                  <div class="variable-name"><span>V112</span></div>
                </div>
                <div class="tank">
                  <span class="tank-upper"></span>
                  <span class="tank-name">HW tank<br/>T93</span>
                  <span class="tank-lower third-tank-lower"></span>
                </div>
                <div class="indicator lower-indicator">
                  <div class="lamp-name"><span>s</span></div>
                  <div class="lamp">
                    <div class="lamp-first"></div>
                    <div class="lamp-second"></div>
                  </div>
                  <div class="variable-name"><span>V102</span></div>
                </div>
                <div class="tank-circle-indic-holder">
                  <div class="circle-indic tr2">
                    <span class="tr-val">42.5</span>
                    <hr/>
                      <span class="tr-val">50</span>
                      <span class="tr-name">TR2</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="pump">
              <span>Pump</span>
            </div>
            <div class="mBotm">
              <span>M101</span>
            </div>
            <div class="main-indic-holder indic-holder">
              <div class="indicator">
                <div class="lamp-name"><span>s</span></div>
                <div class="lamp">
                  <div class="lamp-first"></div>
                  <div class="lamp-second"></div>
                </div>
                <div class="variable-name"><span>V142</span></div>
              </div>
            </div>
            <div class="second-indic-holder indic-holder">
              <div class="indicator">
                <div class="lamp-name"><span>s</span></div>
                <div class="lamp">
                  <div class="lamp-first"></div>
                  <div class="lamp-second"></div>
                </div>
                <div class="variable-name"><span>V141</span></div>
              </div>
            </div>
            <div class="view-circle-indic-holder first-holder">
              <div class="circle-indic">
                <span class="tr-val">502</span>
                <hr/>
                  <span class="tr-val"></span>
              </div>
            </div>
            <div class="view-circle-indic-holder second-holder">
              <div class="circle-indic">
                <span class="tr-val">28.9</span>
                <hr/>
                  <span class="tr-val"></span>
              </div>
            </div>
          </div>
        </div>
        <div class="controller">
          <table>
            <thead>
              <tr>
                <th>Select All</th>
                <th>Variables</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><input type="checkbox"/></td>
                <td>V111</td>
              </tr>
              <tr>
                <td><input type="checkbox"/></td>
                <td>V113</td>
              </tr>
              <tr>
                <td><input type="checkbox"/></td>
                <td>V112</td>
              </tr>
              <tr>
                <td><input type="checkbox"/></td>
                <td>V101</td>
              </tr>
              <tr>
                <td><input type="checkbox"/></td>
                <td>V102</td>
              </tr>
              <tr>
                <td><input type="checkbox"/></td>
                <td>QT1</td>
              </tr>
              <tr>
                <td><input type="checkbox"/></td>
                <td>TE102</td>
              </tr>
              <tr>
                <td><input type="checkbox"/></td>
                <td>....</td>
              </tr>
              <tr>
                <td><input type="checkbox"/></td>
                <td>....</td>
              </tr>
            </tbody>
          </table>
          <div class="ctrl-btn">
            <div class="btn">Set Interval</div>
            <div class="btn">Log data</div>
          </div>
        </div>
      </div>
      <footer><p>&copy; Copyrights 2023 - All rights reserved</p></footer>
    </div>
  );
}

export default App;
